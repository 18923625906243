<template>
  <div>
    <frame :top1="top1" :top2="top2">
      <!-- 头部 -->
 <!--     <div class="head">
        <div class="left">
          <b class="left_tit">标签列表</b>
        </div>

        <div class="right">
          <el-button class="refresh" @click="refresh()" icon="el-icon-refresh"
            >刷新</el-button
          >
        </div>
      </div> -->
      <!-- 筛选部分 -->
      <!-- 表格头部 -->
      <!-- <div class="table_head"> -->
   <!--     <div class="table_head_left">
          <i class="el-icon-s-unfold"></i>
          数据列表
        </div> -->
        <div class="table_head_right">
       
          <transition name="el-fade-in">
            <div v-show="show" class="transition-box">
              <el-form
                ref="form"
                :model="sizeForm"
                label-width="80px"
                size="mini"
              >
                <div class="act_tit">添加标签</div>

                <div class="listcont">
                  <div class="shuai" v-if="admin_type==1">
                    <!-- <div class="selname"></div> -->
                    <el-form-item
                      label="选择商户："
                      label-width="120px"
                      style="margin-top: 5px"
                    >
                    </el-form-item>
                    <el-select v-model="pstoreid" placeholder="请选择商户">
                                            <el-option
                        v-for="item in businessList"
                        :key="item.storeid"
                        :label="item.s_name"
                        :value="item.storeid"
                        @click.native="shopType(item.storeid)"
                      >
                                              </el-option
                      >
                                          </el-select
                    >
                    <!-- <Select  placeholder="请选择" class="sel" v-model="selects.storeid">
											
					<Option  :value="item.storeid" v-for='item,index in selects' :key='index' @click.native="shopType(item.storeid)"></Option>
					 </Select> -->
                  </div>
                <!-- <div class="shuai">
                    
                    <el-form-item
                      label="选择门店："
                      label-width="120px"
                      style="margin-top: 5px"
                    >
                    </el-form-item>
                    <el-select v-model="storeid" placeholder="请选择门店">
                                            <el-option
                        v-for="item in storeList"
                        :key="item.storeid"
                        :label="item.s_name"
                        :value="item.storeid"
                        @click.native="storeidType(item.storeid)"
                      >
                                              </el-option
                      >
                                          </el-select
                    >
                  </div> -->
                </div>
                <el-form-item label="标签名称：" label-width="120px">
                  <el-input v-model="l_name"></el-input>
                </el-form-item>

                <el-form-item label="累计成功交易：" label-width="120px">
                  <el-input v-model="l_count"></el-input>
                </el-form-item>
                <el-form-item label="累计购买金额：" label-width="120px">
                  <el-input v-model="l_money"></el-input>
                </el-form-item>

                <el-form-item size="large">
                  <el-button type="primary" @click="onSubmit"
                    >立即创建</el-button
                  >
                  <el-button @click="show = false">取消</el-button>
                </el-form-item>
              </el-form>
            </div>
          </transition>
        </div>
      <!-- </div> -->
      <!-- 表格渲染 -->
      <div class="table">
        <!-- 设置表格高度  height="500" -->
		<div style="margin-left: -65%;margin-bottom: 10px;">会员>会员列表</div>
		<div class="bck">
			<div  style="width: 95%;font-weight: bold; margin-bottom: 20px;display: flex;margin-left: 2.5%;justify-content: space-between;">		
			<div>会员列表</div>
		<el-button type="danger" size="medium"  @click="show = !show" style="margin-top: -3px;"> 添加 </el-button>
		</div>

        <el-table
          :data="labelList"
          border
          style="width: 95%; margin-left: 2.5%; text-align: center"
        >
          <el-table-column prop="ml_id" label="编号" align="center">
          </el-table-column>
          <el-table-column prop="l_name" label="标签名称" align="center">
          </el-table-column>

          <el-table-column prop="labelCount" label="会员人数" align="center">
          </el-table-column>
          <el-table-column
            prop="end_time"
            label="自动打标签条件"
            align="center"
          >
            <template slot-scope="scope">
                            <span>累计成功交易： {{ scope.row.l_count }}</span>
              &nbsp;
              <span style="margin-left: 50px"
                >累计购买金额： {{ scope.row.l_money }}</span
              >
                          </template
            >
          </el-table-column>

          <el-table-column
            prop="operation"
            label="操作"
            width="180"
            align="center"
          >
            <template slot-scope="scope">
              <span
                style="margin-left: 10px"
                @click="
                  edit(
                    scope.row.l_name,
                    scope.row.l_count,
                    scope.row.l_money,
                    scope.row.ml_id,
                    scope.row.storeid,
                    scope.row.store_name
                  )
                "
              >
                编辑
              </span>
              <span
                style="margin-left: 10px"
                @click="delActive(scope.row.ml_id)"
              >
                删除</span
              >
            </template>
          </el-table-column>
        </el-table>
		<div class="block">
		  <el-pagination
		    @size-change="handleSizeChange"
		    @current-change="handleCurrentChange"
		    :current-page="currentPage4"
		    :page-sizes="[10]"
		    :page-size="pageSize"
		    layout="total, sizes, prev, pager, next, jumper"
		    :total="titlePage"
		  >
		  </el-pagination>
		  <div></div>
		</div>
		</div>
		
      </div>
      <!-- 分页 -->
 

      <!-- <el-popover placement="end"  width="500" height="500px" trigger="click">
        <el-row :gutter="12" style="border:1px solid red">
          <el-col :span="8">
            <el-card shadow="always"> 总是显示 </el-card>
          </el-col>
        </el-row>

        <el-button slot="reference">click 激活</el-button>
      </el-popover> -->
    </frame>
  </div>
</template>

<script>
import frame from "../public/Frame.vue";

export default {
  data() {
    return {
      top1: "6-3",
      top2: ["6"],
      flag: 1,
      // admin_type: "",
      xstoreid: "",
      labelList: [],
      selects: "",
	  admin_type:'',
      selectp: "",
      activeTitle: "",
      //选择活动状态
      active_status: "",
      merchantShow: false,
      show: false,
      changeShow: false,
      /*  businessList: [],
				business_id: '', */
      businessList: [{}],
      business_id: "",
      ml_id: "",
      pstoreid: "",
      storeList: [{}],
      storeid: "",
      s_name: "",
      //添加活动
      l_name: "",
      l_count: "",
      l_money: "",
      titlePage: 10,
      sizeForm: {
        labelList: [],
        name: "",
        merchant: "",
        date1: "",
        date2: "",
        endDate1: "",
        endDate2: "",
        delivery: "",
        type: [],
        resource: "",
        desc: "",
      },
      //修改活动
      sizeForm2: {
        active_id: "",
        active_title: "",
      },
      cur_page: 0, //设置一个默认值
      //表格渲染数据
      tableData: [],

      //总页数
      currentPage4: 1,
      currentpage: 1,
      pageSize: 10,
      // tatal: ,
      value2: true,
    };
  },
  methods: {
    //修改上下架

    //刷新页面按钮
    refresh() {
      location.reload();
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      // console.log(this.pageSize);
      this.pageSize = parseInt(`${val}`);
      // console.log("456", this.currentpage);
      console.log("361", this.pageSize);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentpage = `${val}`;
      // console.log("789", this.currentpage);
      // this.admin_type = this.$storage.getLocal("admin_type");
      let login_type = this.$storage.getLocal("type");
      let token = this.$storage.getLocal("token");
      this.$request
        .memberLabelList({
          token,
          storeid: "",
          login_type: login_type,
          page: `${val}`,
          limit: this.pageSize,
        })
        .then((r) => {
          // console.log(123)
          if (r.code == 0) {
            console.log(r);
            this.labelList = r.data.data;
          }
        });
    },
    delActive(val) {
      let token = this.$storage.getLocal("token");
      this.$request
        .memberLabelDel({
          token,
          ml_id: val,
        })
        .then((r) => {
          if (r.code == 0) {
            location.reload();
          }
        });
    },
    onSubmit() {
      console.log("222", this.storeid);
      console.log("333", this.ml_id);
      let login_type = this.$storage.getLocal("type");
      let token = this.$storage.getLocal("token");
      if (this.ml_id == "") {
        this.$request
          .memberLabelAdd({
            token,
            storeid: this.storeid,
            // ml_id:this.ml_id,
            login_type: login_type,
            name: this.l_name,
            count: this.l_count,
            money: this.l_money,
          })
          .then((r) => {
            // console.log(123)
            if (r.code == 0) {
              this.show = false;
              location.reload();
            }
          });
      } else {
        this.$request
          .memberLabelUp({
            token,
            storeid: this.storeid,
            ml_id: this.ml_id,
            login_type: login_type,
            name: this.l_name,
            count: this.l_count,
            money: this.l_money,
          })
          .then((r) => {
            // console.log(123)
            if (r.code == 0) {
              this.show = false;
              location.reload();
            }
          });
      }
    },

    shopType(value) {
		console.log(value)
		this.storeid=value
      // let token = this.$storage.getLocal("token");
      // this.$request
      //   .getStoreDataList({
      //     token,
      //     pstoreid: value,
      //     login_type: this.$storage.getLocal("type"),
      //   })
      //   .then((r) => {
      //     console.log(123);
      //     if (r.code == 0) {
      //       this.storeList = r.data;
      //       console.log(r);
      //     }
      //   });
    },
    storeidType(value) {
      // this.storeid = value;
    },
    //编辑活动的提交按钮
    onChange() {
      if (this.sizeForm2.title == "") {
        alert("活动名称不能为空，时间不能为空，上下架不能为空");
        console.log("不能为空");
      } else {
      }
    },
    edit(name, count, money, ml_id, storeid, store_name) {
      // console.log(val)

      this.show = true;
      this.l_name = name;
      this.l_count = count;
      this.l_money = money;
      console.log(ml_id);
      console.log(storeid);
      this.ml_id = ml_id;
      this.storeid = storeid;
      let token = this.$storage.getLocal("token");
      this.$request
        .getUpStoreData({
          token,
          storeid: storeid,
        })
        .then((r) => {
          // console.log(123)
          if (r.code == 0) {
            console.log(r);
            this.pstoreid = r.data.pstoreid;
            this.$request
              .getStoreDataList({
                token,
                pstoreid: r.data.pstoreid,
                login_type: this.$storage.getLocal("type"),
              })
              .then((r) => {
                console.log(123);
                if (r.code == 0) {
                  this.storeList = r.data;

                  console.log(r);
                }
              });
          }
        });
      console.log(this.storeid);
    },
  },

  created() {
    // this.admin_type = this.$storage.getLocal("admin_type");
    let login_type = this.$storage.getLocal("type");
	this.admin_type=login_type
    let token = this.$storage.getLocal("token");
    this.$request
      .memberLabelList({
        token,
        storeid: "",
        login_type: login_type,
        page: this.currentpage,
        limit: this.pageSize,
      })
      .then((r) => {
        if (r.code == 0) {
          console.log(r);
          this.titlePage = r.data.count;
          this.labelList = r.data.data;
        }
      });

    if (login_type == "1") {
      this.$request
        .getMerchantData({
          token,
        })
        .then((r) => {
          if (r.code == 0) {
            this.businessList = r.data;
            console.log(r);
          }
        });
    } else if (login_type == "2") {
      this.$request
        .getStoreDataList({
          token,
          pstoreid: "",
          login_type: this.$storage.getLocal("type"),
        })
        .then((r) => {
          if (r.code == 0) {
            this.selectp = r.data;
            console.log(r);
          }
        });
    }
  },
  watch: {},
  components: {
    frame,
  },
};
</script>

<style scoped>
.head {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: rgb(243, 243, 243);
}

.left,
.right {
  display: inline;
}

.left {
  float: left;
  margin-left: 210px;
}

.left_tit {
  border-left: 5px solid red;
  font-size: 22px;
  padding: 5px;
}

.refresh {
  /* border:1px solid red ; */
  float: right;
  margin-right: 10%;
  margin-top: 2px;
}

.screen {
  border: 1px solid #ccc;
  margin-top: 15px;
  width: 85%;
  margin-left: 200px;
  height: 90px;
}

.screen_tit {
  height: 40px;
  background-color: rgb(243, 243, 243);
}

.screen_left {
  float: left;
  margin-left: 50px;
  margin-top: 10px;
}

.screen_right {
  float: right;
  margin-top: 6px;
  margin-right: 10px;
}

.screen_cont {
  margin-left: 20px;
  padding-top: 10px;
  font-size: 18px;
}

.transition-box {
  position: absolute;
  z-index: 2;
  top: 150px;
  left: 27%;
  width: 800px;
  height: 500px;

  box-shadow: 0px 0px 10px #888888;
  border-radius: 4px;
  background-color: #fff;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
}

.act_tit {
  width: 800px;
  height: 50px;
  background-color: rgb(236, 128, 141);
  margin-top: -40px;
  margin-left: -20px;
  margin-bottom: 50px;
  font-size: 20px;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
}

.table_head {
  height: 40px;
  margin-left: 200px;
  width: 85%;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
}

.table_head_left {
  margin-top: 5px;
  margin-left: 20px;
}

.table_head_right li {
  float: right;
  height: 30px;

  list-style: none;
  margin-right: 10px;
}

.block {
  margin-top: 30px;
}

.listcont {
  display: flex;
}

.shuai {
  display: flex;
  /* border:1px solid black */
}

.selname {
  width: 200px;
  border: 1px solid black;
}

.sel {
  /* margin-left: 10px; */
  margin-top: 5px;
}
	.table
	{
	 background-color: rgb(245,247,249);
	 padding-top: 10px;
	 padding-bottom: 25px;
	 min-height: 90vh;
	}
	
	.bck
	{
		width: 85%;
		background-color: white;
		/* margin-top: 20px; */
		/* border: 1px solid red; */
		margin-left: 14%;
		/* margin-top: 20px; */
		padding-top: 20px;
		padding-bottom: 20px;
		/* line-height: 20px; */
	}
	*	{
		/* background-color: red; */
		/* border: 1px solid deeppink; */
		
	}
</style>
